import React, { Fragment, useContext, useState } from 'react';
import Context from '../../contexts/Context';
import Card from '../cards/Card';
import Table from '../tables/Table';
import Head from '../heads/Head';
import BadgeOutline from '../badges/BadgeOutline';
import { SiBitbucket } from "react-icons/si";
import { Dropdown } from 'rsuite';

const Commits = () => {
    const { commits } = useContext(Context);
    const [numberCommits, setNumberCommits] = useState(5);
    const [dropdownCommits, setDropdownCommits] = useState(5);

    const renderCommits = (commits) => {
        const columns = [
            {
                Header: 'Commits',
                accessor: 'col1',
            },
            {
                Header: 'Tecnologias Aplicadas',
                accessor: 'col2',
            },
        ];

        return (
            <Fragment>
                <Table data={commits.slice(0, numberCommits)} columns={columns} classes="table-home" scroll={true}/>
                <div className="text-center text-md-start float-md-start mt-3 mt-md-2 ms-md-2">
                    <BadgeOutline content={"Integração com API do Bitbucket"} icon={<SiBitbucket size={22}/>}/> 
                </div>
                <div className="text-center text-md-start float-md-end mt-2">
                    <span className="text-base me-2">Exibir os Últimos</span>
                    <Dropdown title={dropdownCommits} onSelect={(string, event) => {
                                setNumberCommits(event.target.innerText); 
                                setDropdownCommits(event.target.innerText);
                                var top = document.getElementById('commits').offsetTop - 40;
                                window.scroll({ top: top, behavior: "instant" });
                            }}>
                        <Dropdown.Item>5</Dropdown.Item>
                        <Dropdown.Item>10</Dropdown.Item>
                        <Dropdown.Item>20</Dropdown.Item>
                    </Dropdown>
                    <span className="text-base ms-2">Commits</span>
                </div>
            </Fragment>
        )
    }
    
    return (
        <section className="commits" id="commits">
            <Head content={ 'Últimos Commits (Projetos Pessoais)' } classes={'mt-2'}/>
            <Card id={'commit'} content={ renderCommits(commits) } classes="card-commits" />
        </section>
    );
}

export default Commits;