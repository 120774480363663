import React, { Fragment } from 'react';

const BadgeOutline = ({ id, content, icon, classes }) => {
  return (
    <Fragment key={id}>
        <span className={'badge bg-outline ' + (classes || 'bg-outline-primary')}>{icon} {content}</span>
    </Fragment>
  )
}
  
export default BadgeOutline;