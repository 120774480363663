import React, { Fragment, useState } from 'react';
import HeadPage from '../../components/heads/HeadPage';
import ProfileSection from '../about/sections/Profile';
import ProfileDescriptionSection from '../about/sections/ProfileDescription';
import BibleSection from '../about/sections/Bible';
import Bible2Section from '../about/sections/Bible2';
import CVSection from '../about/sections/CV';
import GithubSection from '../about/sections/Github';
import InterestsSection from '../about/sections/Interests';
import SourcesKnowledgeSection from '../about/sections/SourcesKnowledge';
import { FaUser } from 'react-icons/fa';

const About = () => {
    const initialState = {
        title: "Sobre Mim",
    }

    const [about] = useState(initialState);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12 mb-4">
                    <HeadPage icon={<FaUser className="ms-md-4" size={22} />} title={about.title} classes="card-title-alt"/>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 col-xxl-4 mb-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ProfileSection />
                                </div>
                            </div>
                            <div className="row d-none d-md-block d-xl-none">
                                <div className="col-lg-12 mt-2">
                                    <GithubSection />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-5 mb-4">
                            <ProfileDescriptionSection />
                        </div>
                        <div className="col-lg-12 col-xxl-3 mb-4">
                            <div className="row">
                                <div className="col-md-12 col-lg-4 col-xxl-12 mb-4 d-none d-md-block">
                                    <BibleSection />
                                </div>
                                <div className="col-md-6 col-lg-4 col-xxl-12 mb-4 d-none d-md-block d-xxl-none">
                                    <Bible2Section />
                                </div>
                                <div className="col-md-6 col-lg-4 col-xxl-12 mb-4 h-100 d-block d-md-none d-xxl-block">
                                    <GithubSection />
                                </div>
                                <div className="col-md-6 col-lg-4 col-xxl-12 h-100">
                                    <CVSection />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <InterestsSection />
                </div>
                <div className="col-12">
                    <SourcesKnowledgeSection />
                </div>
                <div className="col-md-6 col-lg-4 col-xxl-12 mb-4 d-block d-md-none">
                    <Bible2Section />
                </div>
                <div className="col-md-12 col-lg-4 col-xxl-12 mb-4 d-block d-md-none">
                    <BibleSection />
                </div>
            </div>
        </Fragment>
    )
}
  
export default About;