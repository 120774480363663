import React, { useState, useEffect } from "react";
import Context from './Context';
import Technologies from '../components/commits/Technologies';
import SummaryHome from '../components/summaries/SummaryHome';
import SummaryProject from '../components/summaries/SummaryProject';
import api from '../services/api'

const ContextWrapper = (props) => {
    const [commits, setCommits] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=site-pessoal,ecommerce,whatsapp-chatbot,dashboard-site-pessoal-api,dashboard-site-pessoal,new-ecommerce-client-web-app')
            .then(({ data }) => {
                if(data) {
                    let commits = [],
                    technologiesColor;

                    commits = data.slice(0, 20).map((value) => {
                        technologiesColor = value.project === "ecommerce" || 
                                            value.project === "dashboard-site-pessoal-api" ? "bg-success" : "";

                        return {
                            col1: <SummaryHome project={value.project} content={value.commit.replaceAll('\n', '')} />,
                            col2: <Technologies technologies={value.technologies} classes={technologiesColor} />
                        }
                    });

                    setCommits(commits);
                }
            })
    }, []);

    const [commitsSP, setCommitsSP] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=site-pessoal')
            .then(({ data }) => {
                if(data) {
                    let commits = [];

                    commits = data.slice(0, 9).map((value) => {
                        

                        return {
                            col1: <SummaryProject project={value.project} content={value.commit.replaceAll('\n', '')} icon="Bitbucket" />,
                            col2: <Technologies technologies={value.technologies}/>
                        }
                    });

                    setCommitsSP(commits);
                }
            })
    }, []);

    const [commitsE, setCommitsE] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=ecommerce')
            .then(({ data }) => {
                if(data) {
                    let commits = [];

                    commits = data.slice(0, 9).map((value) => {
                        return {
                            col1: <SummaryProject project={value.project} content={value.commit.replaceAll('\n', '')} icon="Bitbucket" classes="text-green-dark" />,
                            col2: <Technologies technologies={value.technologies} classes="bg-success" />
                        }
                    });

                    setCommitsE(commits);
                }
            })
    }, []);

    const [commitsWC, setCommitsWC] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=whatsapp-chatbot')
            .then(({ data }) => {
                if(data) {
                    let commits = [];

                    commits = data.slice(0, 9).map((value) => {
                        return {
                            col1: <SummaryProject project={value.project} content={value.commit.replaceAll('\n', '')} icon="Bitbucket" />,
                            col2: <Technologies technologies={value.technologies} />
                        }
                    });

                    setCommitsWC(commits);
                }
            })
    }, []);

    const [commitsDSPAPI, setCommitsDSPAPI] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=dashboard-site-pessoal-api')
            .then(({ data }) => {
                if(data) {
                    let commits = [];

                    commits = data.slice(0, 9).map((value) => {
                        return {
                            col1: <SummaryProject project={value.project} content={value.commit.replaceAll('\n', '')} icon="Bitbucket" classes="text-green-dark"/>,
                            col2: <Technologies technologies={value.technologies} classes="bg-success" />
                        }
                    });

                    setCommitsDSPAPI(commits);
                }
            })
    }, []);

    const [commitsDSP, setCommitsDSP] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=dashboard-site-pessoal')
            .then(({ data }) => {
                if(data) {
                    let commits = [];

                    commits = data.slice(0, 9).map((value) => {
                        return {
                            col1: <SummaryProject project={value.project} content={value.commit.replaceAll('\n', '')} icon="Bitbucket"/>,
                            col2: <Technologies technologies={value.technologies}/>
                        }
                    });

                    setCommitsDSP(commits);
                }
            })
    }, []);

    const [commitsNECWA, setCommitsNECWA] = useState([]);

    useEffect(() => {
        api.get('bitbucket/commits?repositories=new-ecommerce-client-web-app')
            .then(({ data }) => {
                if(data) {
                    let commits = [];

                    commits = data.slice(0, 5).map((value) => {
                        return {
                            col1: <SummaryProject project={value.project} content={value.commit.replaceAll('\n', '')} icon="Bitbucket" />,
                            col2: <Technologies technologies={value.technologies}/>
                        }
                    });

                    setCommitsNECWA(commits);
                }
            })
    }, []);

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=new-ecommerce-client-web-app&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 5).map((value) => {
                        return {
                            col1: <SummaryHome project={value.project} content={value.name} />,
                        }
                    });

                    setTasks(tasks);
                }
            })
    }, []);

    const [tasksSP, setTasksSP] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=site-pessoal&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"site-pessoal"} content={value.name} icon="Asana"/>,
                        }
                    });

                    setTasksSP(tasks);
                }
            })
    }, []);

    const [tasksCompletedSP, setTasksCompletedSP] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=site-pessoal&type=completed')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"site-pessoal"} content={value.name} icon="Asana"/>,
                        }
                    });

                    setTasksCompletedSP(tasks);
                }
            })
    }, []);

    const [tasksE, setTasksE] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=ecommerce&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"site-pessoal"} content={value.name} icon="Asana" classes="text-green-dark"/>,
                        }
                    });

                    setTasksE(tasks);
                }
            })
    }, []);

    const [tasksCompletedE, setTasksCompletedE] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=ecommerce&type=completed')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"site-pessoal"} content={value.name} icon="Asana" classes="text-green-dark"/>,
                        }
                    });

                    setTasksCompletedE(tasks);
                }
            })
    }, []);

    const [tasksWC, setTasksWC] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=whatsapp-chatbot&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"site-pessoal"} content={value.name} icon="Asana" />,
                        }
                    });

                    setTasksWC(tasks);
                }
            })
    }, []);

    const [tasksCompletedWC, setTasksCompletedWC] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=whatsapp-chatbot&type=completed')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"whatsapp-chatbot"} content={value.name} icon="Asana" />,
                        }
                    });

                    setTasksCompletedWC(tasks);
                }
            })
    }, []);

    const [tasksDSPAPI, setTasksDSPAPI] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=dashboard-site-pessoal-api&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"dashboard-site-pessoal-api"} content={value.name} icon="Asana" classes="text-green-dark"/>,
                        }
                    });

                    setTasksDSPAPI(tasks);
                }
            })
    }, []);

    const [tasksCompletedDSPAPI, setTasksCompletedDSPAPI] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=dashboard-site-pessoal-api&type=completed')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];
                    
                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"dashboard-site-pessoal-api"} content={value.name} icon="Asana" classes="text-green-dark"/>,
                        }
                    });

                    setTasksCompletedDSPAPI(tasks);
                }
            })
    }, []);

    const [tasksDSP, setTasksDSP] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=dashboard-site-pessoal&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"dashboard-site-pessoal"} content={value.name} icon="Asana"/>,
                        }
                    });

                    setTasksDSP(tasks);
                }
            })
    }, []);

    const [tasksCompletedDSP, setTasksCompletedDSP] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=dashboard-site-pessoal&type=completed')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];
                    
                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"dashboard-site-pessoal"} content={value.name} icon="Asana"/>,
                        }
                    });

                    setTasksCompletedDSP(tasks);
                }
            })
    }, []);

    const [tasksNECWA, setTasksNECWA] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=new-ecommerce-client-web-app&type=to-do')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"new-ecommerce-client-web-app"} content={value.name} icon="Asana"/>,
                        }
                    });

                    setTasksNECWA(tasks);
                }
            })
    }, []);

    const [tasksCompletedNECWA, setTasksCompletedNECWA] = useState([]);

    useEffect(() => {
        api.get('asana/tasks?projects=new-ecommerce-client-web-app&type=completed')
            .then(({ data }) => {
                if(data) {
                    let tasks = [];

                    tasks = data.slice(0, 3).map((value) => {
                        return {
                            col1: <SummaryProject project={"new-ecommerce-client-web-app"} content={value.name} icon="Asana"/>,
                        }
                    });

                    setTasksCompletedNECWA(tasks);
                }
            })
    }, []);

    const [showPopoverWhatsapp, setShowPopoverWhatsapp] = useState({
		show: false
	});

    return (
        <Context.Provider value={{
            commits,
            setCommits,
            commitsSP,
            setCommitsSP,
            tasks,
            tasksSP,
            setTasksSP,
            tasksCompletedSP,
            setTasksCompletedSP,
            commitsE, 
            setCommitsE,
            tasksE, 
            setTasksE,
            tasksCompletedE, 
            setTasksCompletedE,
            commitsWC, 
            setCommitsWC,
            tasksWC, 
            setTasksWC,
            tasksCompletedWC, 
            setTasksCompletedWC,
            showPopoverWhatsapp,
            commitsDSPAPI, 
            setCommitsDSPAPI,
            tasksDSPAPI, 
            setTasksDSPAPI,
            tasksCompletedDSPAPI, 
            setTasksCompletedDSPAPI,
            commitsDSP, 
            setCommitsDSP,
            tasksDSP, 
            setTasksDSP,
            tasksCompletedDSP, 
            setTasksCompletedDSP,
            commitsNECWA, 
            setCommitsNECWA,
            tasksNECWA, 
            setTasksNECWA,
            tasksCompletedNECWA, 
            setTasksCompletedNECWA,
            setShowPopoverWhatsapp
        }}>
            {props.children}
        </Context.Provider>
    );
}

export default ContextWrapper;