import React, { Fragment, useState } from 'react';
import CardBackground from '../../../components/cards/CardBackground';
import Head from '../../../components/heads/Head';
import BgProfile from '../../../assets/images/backgrounds/bg-education.jpeg';
import { Timeline } from 'rsuite';

const Education = () => {
    const initialState = [
        /* {
            job: 'Cursos e Livros Complementares em Tecnologia',
            institute: 'Packt',
            dateIn: '2020',
            dateOut: 'Momento Atual',
            description: 'Utilizo a plataforma do "Packt" para me aprofundar '
                       + 'em uma determinada linguagem de programação ou framework, '
                       + 'através dos diversos cursos e livros disponibilizados pela plataforma.',
            workload: ''
        }, */
        {
            job: 'Cursos Complementares em Tecnologia',
            institute: 'Udemy',
            dateIn: '2017',
            dateOut: 'Momento Atual',
            description: 'Utilizo a plataforma da "Udemy" para realização de cursos, '
                       + 'dando continuidade assim aos meus estudos e me mantendo '
                       + 'atualizado com as novas tecnologias de mercado. '
                       + 'Atualmente tenho realizado cursos para ampliar um '
                       + 'pouco mais o meu conhecimento em React JS e Next JS.',
            workload: ''
        },
        {
            job: 'Tecnólogo em Análise e Desenvolvimento de Sistemas (Superior Completo)',
            institute: 'Faculdade de Tecnologia de São Caetano do Sul - Antonio Russo',
            dateIn: '2014',
            dateOut: '2017',
            description: '',
            workload: '2800 Horas'
        },
        {
            job: 'Bacharelado em Ciência da Computação (Superior Incompleto)',
            institute: 'Universidade Nove de Julho',
            dateIn: '2010',
            dateOut: '2012',
            description: '',
            workload: '1600 de 3660 horas'
        }
    ];

    const [educations] = useState(initialState);

    const renderEducation = () => {
        return (
            <Fragment>
                <Head content={'Educação'} classes="h2 mt-2" />
                <Timeline className="timeline-education">
                    {
                        educations.map((value, index) => (
                            <Timeline.Item key={index}>
                                <div className="rs-timeline-content">
                                    <h6 className="lh-md">{value.job}</h6>
                                    <div className="my-1 fw-bolder-light">{value.institute} / {value.dateIn} ~ {value.dateOut}</div>
                                    <p>{value.description}</p>
                                    {
                                        value.workload ? (
                                            <span>Carga Horária: {value.workload}</span>
                                        ) : null
                                    }
                                </div>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            </Fragment>
        )
    }

    return (
        <section className="education">
            <CardBackground id={'education'} content={ renderEducation() } image={BgProfile} classes={'card-timeline'}/>
        </section>
    )
}

export default Education;