import React, { Fragment, useState } from 'react';
import FormContactSection from './sections/FormContact';
import InfoContactSection from './sections/InfoContact';
import BibleSection from './sections/Bible';
import HeadPage from '../../components/heads/HeadPage';
import CardImage from '../../components/cards/CardImage';
import { FaPaperPlane } from 'react-icons/fa';

const Contact = () => {
    const initialState = {
        title: "Contato",
    }

    const [contact] = useState(initialState);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12 mb-4">
                    <HeadPage icon={<FaPaperPlane className="ms-md-4" size={22} />} title={contact.title} classes="card-title-alt"/>
                </div>
                <div className="col-md-4 col-lg-5 col-xl-3 col-xxl-4 mb-4 d-none d-md-block">
                    <CardImage classes={'bg bg-contact h-100'} />
                </div>
                <div className="col-md-8 col-lg-7 col-xl-5 col-xxl-5 mb-4">
                    <FormContactSection />
                </div>
                <div className="col-12 col-xl-4 col-xxl-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-4 col-xl-12 mb-4 order-1 order-md-1 order-lg-1 order-xl-0">
                            <BibleSection />
                        </div>
                        <div className="col-md-12 col-lg-8 col-xl-12 mb-4">
                            <InfoContactSection />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Contact;