import React from 'react';

const CardImage = ({ badge, classes, content }) => {
  return (
    <div className={'card card-image p-0 ' + classes}>
      {
        badge ? <span className="badge bg-secondary">{content}</span> : ''
      }
    </div>
  )
}
  
export default CardImage;