import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';

const InfoContact = () => {
    const initialState = {
        site: "www.wilsoncastro.dev",
        phone: "(11) 5676-4038",
        cellPhone: "(11) 97748-7190",
        email: "contato@wilsoncastro.dev",
        localization: "São Paulo (Zona Sul), SP",
    }

    const [info] = useState(initialState);

    const renderInfoContact = () => {
        return (
            <Fragment>
                <Head content={'Contatos'} classes="h2 mt-0 text-white" />
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-12 d-md-none">
                        <a href="https://www.linkedin.com/in/w-castro/" className="badge bg-transparent-alt text-white">
                            <i className="fab fa-linkedin ms-2"></i><span>Ver Perfil no LinkedIn</span>
                        </a>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                        <a href="/" className="badge bg-transparent-alt text-white text-decoration-none">
                            <i className="fa fa-globe ms-2"></i><span>{info.site}</span>
                        </a>
                    </div>
                    {/* <div className="col-12 col-md-6 col-xl-12">
                        <a href="tel:5676-4038" className="badge bg-transparent-alt text-white text-decoration-none">
                            <i className="fa fa-phone ms-2"></i><span>{info.phone}</span>
                        </a>
                    </div> */}
                    <div className="col-12 col-md-6 col-xl-12">
                        <a href="tel:97748-7190" className="badge bg-transparent-alt text-white text-decoration-none">
                            <i className="fa fa-mobile-alt ms-2"></i><span>{info.cellPhone}</span>
                        </a>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                        <a href="mailto:contato@wilsoncastro.dev" className="badge bg-transparent-alt text-white text-decoration-none">
                            <i className="fa fa-envelope ms-2"></i><span>{info.email}</span>
                        </a>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                        <div className="badge bg-transparent-alt text-white text-decoration-none">
                            <i className="fa fa-map-marker-alt ms-2"></i><span>{info.localization}</span>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <section className="info-contact">
            <Card id={'info-contact'} content={renderInfoContact()} classes={'h2 bg bg-green-700'} />
        </section>
    )
}

export default InfoContact;