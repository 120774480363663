import React, { Fragment } from 'react';
import CommitsSP from '../components/commits/CommitsSP';
import CommitsE from '../components/commits/CommitsE';
import CommitsWC from '../components/commits/CommitsWC';
import CommitsDSPAPI from '../components/commits/CommitsDSPAPI';
import CommitsDSP from '../components/commits/CommitsDSP';
import CommitsNECWA from '../components/commits/CommitsNECWA';
import TasksSP from '../components/tasks/TasksSP';
import TasksE from '../components/tasks/TasksE';
import TasksWC from '../components/tasks/TasksWC';
import TasksDSPAPI from '../components/tasks/TasksDSPAPI';
import TasksDSP from '../components/tasks/TasksDSP';
import TasksNECWA from '../components/tasks/TasksNECWA';
import TasksCompletedSP from '../components/tasks/TasksCompletedSP';
import TasksCompletedE from '../components/tasks/TasksCompletedE';
import TasksCompletedWC from '../components/tasks/TasksCompletedWC';
import TasksCompletedDSPAPI from '../components/tasks/TasksCompletedDSPAPI';
import TasksCompletedDSP from '../components/tasks/TasksCompletedDSP';
import TasksCompletedNECWA from '../components/tasks/TasksCompletedNECWA';
import SPScreenshot1 from '../assets/images/portfolio/site-pessoal/screenshot1.png';
import SPScreenshot2 from '../assets/images/portfolio/site-pessoal/screenshot2.png';
import SPScreenshot3 from '../assets/images/portfolio/site-pessoal/screenshot3.png';
import SPScreenshot4 from '../assets/images/portfolio/site-pessoal/screenshot4.png';
import SPScreenshot5 from '../assets/images/portfolio/site-pessoal/screenshot5.png';
import SPScreenshot6 from '../assets/images/portfolio/site-pessoal/screenshot6.png';
import EScreenshot1 from '../assets/images/portfolio/ecommerce/screenshot1.png';
import EScreenshot2 from '../assets/images/portfolio/ecommerce/screenshot2.png';
import EScreenshot3 from '../assets/images/portfolio/ecommerce/screenshot3.png';
import EScreenshot4 from '../assets/images/portfolio/ecommerce/screenshot4.png';
import EScreenshot5 from '../assets/images/portfolio/ecommerce/screenshot5.png';
import EScreenshot6 from '../assets/images/portfolio/ecommerce/screenshot6.png';
import EScreenshot7 from '../assets/images/portfolio/ecommerce/screenshot7.png';
import WScreenshot3 from '../assets/images/portfolio/whatsapp-chatbot/screenshot3.jpeg';
import WScreenshot4 from '../assets/images/portfolio/whatsapp-chatbot/screenshot4.jpeg';
import WScreenshot5 from '../assets/images/portfolio/whatsapp-chatbot/screenshot5.jpeg';
import DSPAPIScreenshot1 from '../assets/images/portfolio/dashboard-api/screenshot1.png';
import DSPAPIScreenshot2 from '../assets/images/portfolio/dashboard-api/screenshot2.png';
import DSPAPIScreenshot3 from '../assets/images/portfolio/dashboard-api/screenshot3.png';
import DSPAPIScreenshot4 from '../assets/images/portfolio/dashboard-api/screenshot4.png';
import DSPAPIScreenshot5 from '../assets/images/portfolio/dashboard-api/screenshot5.png';
import DSPAPIScreenshot6 from '../assets/images/portfolio/dashboard-api/screenshot6.png';
import DSPScreenanimation1 from '../assets/images/portfolio/dashboard-site-pessoal/screenanimation1.gif';
import DSPScreenanimation2 from '../assets/images/portfolio/dashboard-site-pessoal/screenanimation2.gif';
import DSPScreenshot1 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot1.png';
import DSPScreenshot2 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot2.png';
import DSPScreenshot3 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot3.png';
import DSPScreenshot4 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot4.png';
import DSPScreenshot5 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot5.png';
import DSPScreenshot6 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot6.png';
import DSPScreenshot7 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot7.png';
import DSPScreenshot8 from '../assets/images/portfolio/dashboard-site-pessoal/screenshot8.png';
import NEScreenshot1 from '../assets/images/portfolio/new-commerce/screenshot1.jpg';
import NECWAcreenanimation1 from '../assets/images/portfolio/new-commerce/client-web-app/screenanimation1.gif';
import NECWAcreenanimation2 from '../assets/images/portfolio/new-commerce/client-web-app/screenanimation2.gif';
import NECWAcreenshot3 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot1.png';
import NECWAcreenshot1 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot2.png';
import NECWAcreenshot2 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot3.png';
import NECWAcreenshot4 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot4.png';
import NECWAcreenshot5 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot5.png';
import NECWAcreenshot6 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot6.png';
import NECWAcreenshot7 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot7.png';
import NECWAcreenshot8 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot8.png';
import NECWAcreenshot9 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot9.png';
import NECWAcreenshot10 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot10.png';
import NECWAcreenshot11 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot11.png';
import NECWAcreenshot12 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot12.png';
import NECWAcreenshot13 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot13.png';
import NECWAcreenshot14 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot14.png';
import NECWAcreenshot15 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot15.png';
import NECWAcreenshot16 from '../assets/images/portfolio/new-commerce/client-web-app/screenshot16.png';
import DOScreenanimation1 from '../assets/images/portfolio/devops/animation1.gif';
import DOScreenanimation2 from '../assets/images/portfolio/devops/animation2.gif';
import DOArch1 from '../assets/images/portfolio/devops/arch1.svg';
import DOArch2 from '../assets/images/portfolio/devops/arch2.svg';
import DOScreenshot1 from '../assets/images/portfolio/devops/screenshot1.png';
import DOScreenshot2 from '../assets/images/portfolio/devops/screenshot2.png';
import DOScreenshot3 from '../assets/images/portfolio/devops/screenshot3.png';
import SScreenshot1 from '../assets/images/portfolio/services/screenshot1.png';
import SScreenshot2 from '../assets/images/portfolio/services/screenshot2.png';
import SScreenshot3 from '../assets/images/portfolio/services/screenshot3.png';
import SScreenshot5 from '../assets/images/portfolio/services/screenshot5.png';
import SScreenshot6 from '../assets/images/portfolio/services/screenshot6.png';
import SScreenshot7 from '../assets/images/portfolio/services/screenshot7.png';
import SScreenshot8 from '../assets/images/portfolio/services/screenshot8.png';
import SScreenshot9 from '../assets/images/portfolio/services/screenshot9.png';
import SScreenshot10 from '../assets/images/portfolio/services/screenshot10.png';
import SScreenshot11 from '../assets/images/portfolio/services/screenshot11.png';
import SScreenshot12 from '../assets/images/portfolio/services/screenshot12.png';
import SScreenshot13 from '../assets/images/portfolio/services/screenshot13.png';

export const portfolioData = [
    {
        id: 1,
        projectName: 'Site Pessoal - wCastro',
        objective: 'Este projeto tem como objetivo o desenvolvimento de um site pessoal '
            + 'para realizar a divulgação do meu currículo e dos meus projetos pessoais '
            + 'e também colocar em prática os meus estudos sobre React JS.',
        technologies: [
            'React', 'Javascript', 'HTML5', 'CSS3', 'SASS', 'Bootstrap', "Nginx", "Docker"
        ],
        url: '/',
        status: 'Sustentação',
        env: "production",
        demo: false,
        commits: <CommitsSP />,
        tasks: <TasksSP />,
        tasksCompleted: <TasksCompletedSP />,
        screenDesktop: [SPScreenshot1, SPScreenshot2, SPScreenshot3, SPScreenshot4, SPScreenshot5, SPScreenshot6],
        screenMobile: [],
        credits: {
            description: (
                <Fragment>
                    <p>
                        Seguem os créditos das imagens, ícones, logos, pacotes do Node, apis e referências utilizadas <br />
                        no desenvolvimento deste projeto.
                    </p>
                </Fragment>
            ),
            icons: [
                {
                    name: 'Ícones "Font Awesome"',
                    link: 'https://fontawesome.com/',
                    platform: 'FontAwesome'
                },
                {
                    name: 'Ícones "React Icons"',
                    link: 'https://react-icons.github.io/react-icons/',
                    platform: 'React Icons'
                },
                {
                    name: 'Ícone "Internet" de Icons8',
                    link: 'https://icons8.com/icon/53419/internet',
                    platform: 'Icons8'
                },
                {
                    name: 'Ícone "Web Design" de Icons8',
                    link: 'https://icons8.com.br/icon/79779/web-design',
                    platform: 'Icons8'
                },
                {
                    name: 'Ícone "Vários dispositivos" de Icons8',
                    link: 'https://icons8.com.br/icon/8576/v%C3%A1rios-dispositivos',
                    platform: 'Icons8'
                },
                {
                    name: 'Ícone "Aprendizagem" de Icons8',
                    link: 'https://icons8.com.br/icon/78148/aprendizagem',
                    platform: 'Icons8'
                },
                {
                    name: 'Ícone "Desenvolvedor" de Icons8',
                    link: 'https://icons8.com.br/icon/77971/desenvolvedor',
                    platform: 'Icons8'
                }
            ],
            images: [
                {
                    name: 'Imagem "JavaScript in progress" de Clément Hélardot',
                    link: 'https://unsplash.com/photos/95YRwf6CNw8',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "1 - PHP Plush Elephant on a Macbook Pro, with PHP Storm running on it."'
                        + ' de  Ben Griffiths',
                    link: 'https://unsplash.com/photos/4wxWBy8Jo1I',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "2 - PHP Plush Elephant on a Macbook Pro, with PHP Storm running on it."'
                        + ' de  Ben Griffiths',
                    link: 'https://unsplash.com/photos/Bj6ENZDMSDY',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "Statistics on a laptop"'
                        + ' de  Carlos Muza',
                    link: 'https://unsplash.com/photos/hpjSkU2UYSU',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "Programming PHP"'
                        + ' de  Peter Masełkowski',
                    link: 'https://unsplash.com/photos/N135eczYTAs',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "Code on a laptop screen"'
                        + ' de  Luca Bravo',
                    link: 'https://unsplash.com/photos/XJXWbfSo2f0',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "Coding workstation"'
                        + ' de  Farzad Nazifi',
                    link: 'https://unsplash.com/photos/p-xSl33Wxyc',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "Macbook pro on black wooden"'
                        + ' de  AltumCode',
                    link: 'https://unsplash.com/photos/PNbDkQ2DDgM',
                    platform: 'Unsplash'
                },
                {
                    name: 'Imagem "Photography of People Graduating"'
                        + ' de  Emily Ranquist',
                    link: 'https://www.pexels.com/photo/photography-of-people-graduating-1205651/',
                    platform: 'Pexels'
                },
                {
                    name: 'Imagem do "Avatar"'
                        + ' criado no Avatar Maker',
                    link: 'https://avatarmaker.com/',
                    platform: 'Avatar Maker'
                }
            ],
            logos: [
                {
                    name: 'Logo "Laravel" de VectorLogoZone',
                    link: 'https://www.vectorlogo.zone/logos/laravel/index.html',
                    platform: 'VectorLogoZone'
                },
                {
                    name: 'Logo "MySQL" de VectorLogoZone',
                    link: 'https://www.vectorlogo.zone/logos/mysql/index.html',
                    platform: 'VectorLogoZone'
                },
                {
                    name: 'Logo "PHP" de VectorLogoZone',
                    link: 'https://www.php.net/download-logos.php',
                    platform: 'PHP'
                },
                {
                    name: 'Logo "JS" de Chris Williams',
                    link: 'https://commons.wikimedia.org/wiki/File:Unofficial_JavaScript_logo.svg',
                    platform: 'Wikimedia'
                },
                {
                    name: 'Logo "React" de VectorLogoZone',
                    link: 'https://www.vectorlogo.zone/logos/reactjs/index.html',
                    platform: 'VectorLogoZone'
                },
                {
                    name: 'Logo "Google"',
                    link: 'https://commons.wikimedia.org/wiki/Category:Google_SVG_logos#/media/File:Google_2015_logo.svg',
                    platform: 'Wikimedia'
                },
                {
                    name: 'Logo "StackOverflow"',
                    link: 'https://stackoverflow.design/brand/logo/',
                    platform: 'StackOverflow'
                },
                {
                    name: 'Logo "GitHub"',
                    link: 'https://commons.wikimedia.org/wiki/File:Octicons-mark-github.svg',
                    platform: 'Wikimedia'
                },
                {
                    name: 'Logo "Udemy"',
                    link: 'https://www.vectorlogo.zone/logos/udemy/index.html',
                    platform: 'Udemy'
                },
                {
                    name: 'Logo "Packt Publishing"',
                    link: 'https://seekvectorlogo.com/packt-publishing-vector-logo-svg/',
                    platform: 'Packt Publishing'
                },
                {
                    name: 'Logo "Medium"',
                    link: 'https://www.vectorlogo.zone/logos/medium/index.html',
                    platform: 'VectorLogoZone'
                },
                {
                    name: 'Logo "W3Schools"',
                    link: 'https://pt.wikipedia.org/wiki/Ficheiro:W3Schools_logo.svg',
                    platform: 'W3Schools'
                }
            ],
            packages: [
                {
                    name: 'Pacote "@fortawesome/fontawesome-free"'
                        + ' de Fort Awesome',
                    link: 'https://www.npmjs.com/package/@fortawesome/fontawesome-free',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "axios"'
                        + ' de Axios',
                    link: 'https://www.npmjs.com/package/axios',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "bootstrap"'
                        + ' de Bootstrap',
                    link: 'https://www.npmjs.com/package/bootstrap',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "emailjs"'
                        + ' de Eleith',
                    link: 'https://www.npmjs.com/package/emailjs',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "node-sass"'
                        + ' de Sass',
                    link: 'https://www.npmjs.com/package/sass',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-copy-to-clipboard"'
                        + ' de Eleith',
                    link: 'https://www.npmjs.com/package/react-copy-to-clipboard',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-custom-scrollbars"'
                        + ' de Malte Wessel',
                    link: 'https://www.npmjs.com/package/react-custom-scrollbars',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-icons"'
                        + ' de react-icons',
                    link: 'https://www.npmjs.com/package/react-icons',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-particles-js"'
                        + ' de Simone Bembi',
                    link: 'https://www.npmjs.com/package/react-particles-js',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-custom-scrollbars"'
                        + ' de Malte Wessel',
                    link: 'https://www.npmjs.com/package/react-custom-scrollbars',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-table"'
                        + ' de Tanner Linsley',
                    link: 'https://www.npmjs.com/package/react-table',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-tooltip"'
                        + ' de Wang Zixiao',
                    link: 'https://www.npmjs.com/package/react-tooltip',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "react-wavify"'
                        + ' de Jaxson Van Doorn',
                    link: 'https://www.npmjs.com/package/react-wavify',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "rsuite"'
                        + ' de React Suite',
                    link: 'https://www.npmjs.com/package/rsuite',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "sass-loader"'
                        + ' de webpack-contrib',
                    link: 'https://www.npmjs.com/package/sass-loader',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "sweetalert2"'
                        + ' de SweetAlert2',
                    link: 'https://www.npmjs.com/package/sweetalert2',
                    platform: 'NPM'
                }
            ],
            apis: [
                {
                    name: 'API do "Bitbucket"',
                    link: 'https://developer.atlassian.com/bitbucket/api/2/reference/resource/',
                    platform: 'Bitbucket'
                },
                {
                    name: 'API do "Asana"',
                    link: 'https://developers.asana.com/docs/asana',
                    platform: 'Asana'
                }
            ],
            references: [
                {
                    name: 'Documentação do "React JS"',
                    link: 'https://pt-br.reactjs.org/docs/getting-started.html',
                    platform: 'React JS'
                },
                {
                    name: 'Documentação do "Create React App"',
                    link: 'https://create-react-app.dev/docs/getting-started',
                    platform: 'Create React App'
                }
            ]
        }
    },
    {
        id: 2,
        projectName: 'E-commerce - wCastro',
        objective: 'Este projeto teve como objetivo a construção de um e-commerce do zero usando Laravel '
            + 'com intuito de aprimorar cada vez mais as minhas habilidades em PHP, Laravel e MySQL. '
            + 'O projeto acabou sendo descontinuado, mas será utilizado como base para o desenvolvimento de '
            + 'novos projetos com novas tecnologias.',
        technologies: [
            "PHP", "Laravel", "MySQL", "Javascript", "HTML5", "CSS3", "SASS", "Bootstrap", "Apache2", "Docker"
        ],
        url: '',
        url2: '',
        github: 'https://github.com/wilsoncastrodev/ecommerce',
        status: 'Descontinuado',
        env: "",
        demo: false,
        commits: <CommitsE />,
        tasks: <TasksE />,
        tasksCompleted: <TasksCompletedE />,
        screenDesktop: [EScreenshot1, EScreenshot2, EScreenshot3, EScreenshot4, EScreenshot5, EScreenshot6, EScreenshot7],
        screenMobile: [],
        credits: {}
    },
    {
        id: 3,
        projectName: 'Whatsapp Chatbot - wCastro',
        objective: 'O projeto visa o desenvolvimento de um assistente virtual para Whatsapp com objetivo '
            + 'de facilitar e agilizar o meu primeiro contato com o recrutador/headhunter. '
            + 'Além disso, o projeto tem o intuito de me ajudar '
            + 'a me aprofundar um pouco mais em Javascript, utilizando Node.js no Back-End.',
        technologies: [
            "Javascript", "Node.js", "Docker"
        ],
        url: '',
        github: 'https://github.com/wilsoncastrodev/whatsapp-chatbot',
        status: 'Finalizado',
        env: "",
        demo: false,
        commits: <CommitsWC />,
        tasks: <TasksWC />,
        tasksCompleted: <TasksCompletedWC />,
        screenDesktop: [],
        screenMobile: [WScreenshot3, WScreenshot4, WScreenshot5],
        credits: {
            description: (
                <Fragment>
                    <p>
                        Seguem os créditos das ícones, pacotes do Node, apis e referências utilizadas <br />
                        no desenvolvimento deste projeto.
                    </p>
                </Fragment>
            ),
            icons: [
                {
                    name: 'Ícones "Emojipedia"',
                    link: 'https://emojipedia.org/',
                    platform: 'Emojipedia'
                },
            ],
            images: [],
            logos: [],
            packages: [
                {
                    name: 'Pacote "@googlemaps/google-maps-services-js"'
                        + ' de Google Maps',
                    link: 'https://www.npmjs.com/package/@googlemaps/google-maps-services-js',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "@wppconnect-team/wppconnect"'
                        + ' de Wppconnect Team',
                    link: 'https://www.npmjs.com/package/@wppconnect-team/wppconnect',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "axios"'
                        + ' de Axios',
                    link: 'https://www.npmjs.com/package/axios',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "cep-promise"'
                        + ' de BrasilAPI',
                    link: 'https://www.npmjs.com/package/cep-promise',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "es7-sleep"'
                        + ' de Secbone',
                    link: 'https://www.npmjs.com/package/es7-sleep',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "google-spreadsheet"'
                        + ' de Theo Ephraim',
                    link: 'https://www.npmjs.com/package/google-spreadsheet',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "moment"'
                        + ' de Moment.js',
                    link: 'https://www.npmjs.com/package/moment',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "nodemailer"'
                        + ' de Nodemailer',
                    link: 'https://www.npmjs.com/package/nodemailer',
                    platform: 'NPM'
                },
                {
                    name: 'Pacote "rrule"'
                        + ' de Jakub Roztocil',
                    link: 'https://www.npmjs.com/package/rrule',
                    platform: 'NPM'
                },
            ],
            apis: [
                {
                    name: 'API do "Google Sheets"',
                    link: 'https://developers.google.com/sheets/api',
                    platform: 'Google'
                },
                {
                    name: 'API do "Google Maps"',
                    link: 'https://developers.google.com/maps/documentation/directions/overview',
                    platform: 'Google'
                }
            ],
            references: [
                {
                    name: 'Documentação do "Node.js"',
                    link: 'https://nodejs.org/en/docs/',
                    platform: 'Node.js'
                },
                {
                    name: 'Documentação do "WPPConnect"',
                    link: 'https://wppconnect.io/docs/tutorial/intro',
                    platform: 'WPPConnect'
                },
                {
                    name: 'Documentação do "Nodemailer"',
                    link: 'https://nodemailer.com/usage/',
                    platform: 'Nodemailer'
                },
                {
                    name: 'Documentação do "Moment.js"',
                    link: 'https://momentjs.com/',
                    platform: 'Moment.js'
                }
            ]
        }
    },
    {
        id: 4,
        projectName: 'Dashboard API - Site Pessoal - wCastro',
        objective: 'O projeto Dashboard API visa o desenvolvimento de uma API para fornecer a ' +
            'comunicação entre a Dashboard (Painel Administrativo) e o meu Site Pessoal. A construção desse ' +
            'projeto me permite trabalhar minhas habilidades em Javascript e Node.js e ' +
            'adquirir novas habilidades como Node.js com o framework Express e o com banco de dados não relacional MongoDB.',
        technologies: [
            "Javascript", "Node.js", "Express", "MongoDB", "API Rest", "Nginx", "Docker"
        ],
        url: '',
        github: 'https://github.com/wilsoncastrodev/dashboard-site-pessoal-api',
        status: 'Andamento',
        env: "test",
        demo: true,
        commits: <CommitsDSPAPI />,
        tasks: <TasksDSPAPI />,
        tasksCompleted: <TasksCompletedDSPAPI />,
        screenDesktop: [DSPAPIScreenshot1, DSPAPIScreenshot2, DSPAPIScreenshot3, DSPAPIScreenshot4, DSPAPIScreenshot5, DSPAPIScreenshot6],
        screenMobile: [],
        credits: {}
    },
    {
        id: 5,
        projectName: 'Dashboard - Site Pessoal - wCastro',
        objective: 'Este projeto tem como objetivo criar um Painel Administrativo simples ' +
            'e intuitivo para o meu Site Pessoal, com o intuito de facilitar a configuração e o ' +
            'gerenciamento de seu conteúdo. O desenvolvimento desse Painel Administrativo também me permiti continuar ' +
            'desenvolvendo minhas habilidades em Javascript, React, Redux e outras habilidades em Front-End.',
        technologies: [
            "Javascript", "Typescript", "React", "Redux", "SASS", "HTML", "CSS", "Bootstrap", "Nginx", "Docker"
        ],
        url: 'https://dashboard.test.wilsoncastro.dev/',
        github: 'https://github.com/wilsoncastrodev/dashboard-site-pessoal',
        status: 'Andamento',
        env: "test",
        demo: true,
        commits: <CommitsDSP />,
        tasks: <TasksDSP />,
        tasksCompleted: <TasksCompletedDSP />,
        screenDesktop: [DSPScreenanimation1, DSPScreenanimation2, DSPScreenshot1, DSPScreenshot2, DSPScreenshot3, DSPScreenshot4, DSPScreenshot5, DSPScreenshot6, DSPScreenshot7, DSPScreenshot8],
        screenMobile: [],
        credits: {}
    },
    {
        id: 6,
        projectName: 'New E-commerce - wCastro',
        objective: 'Este projeto visa o desenvolvimento de um novo e-commerce utilizando como base o ' +
            'outro e-commerce desenvolvido em PHP. A diferença desse novo e-commerce para outro, ' +
            'é que este o foco do desenvolvimento não será apenas no aprendizado de tecnologias, ' +
            'mas também no aprendizado de aspectos não funcionais de uma aplicação, como segurança, ' +
            'desempenho, escalabilidade, acessibilidade, usabilidade, entre outros. Este será um projeto ' +
            'de longo prazo, com o intuito simples de me manter preparado para os desafios do mercado ' +
            'tecnológico, além é claro, de me ajudar a continuar aprimorando minhas habilidades de ' +
            'desenvolvimento de aplicações Web.',
        technologies: [
            "PHP", "Laravel", "Swoole", "Nginx", "Node.js", "Express.js",
            "MongoDB", "PostgreSQL", "Angular", "Javascript", "Typescript",
            "Next.js", "React", "Redux", "SASS", "HTML", "CSS", "Bootstrap", "Docker"
        ],
        url: '',
        github: '',
        status: 'Andamento',
        env: "test",
        demo: true,
        commits: null,
        tasks: null,
        tasksCompleted: null,
        screenDesktop: [NEScreenshot1],
        screenMobile: [],
        credits: {}
    },
    {
        id: 7,
        projectName: 'New E-commerce Web App - wCastro',
        objective: 'O projeto New E-commerce Web App é um subprojeto do projeto New E-commerce que tem ' +
            'como objetivo o desenvolvimento do front-end da loja virtual. A ideia deste projeto ' +
            'é construir uma aplicação que proporcione uma boa experiência para o usuário, sempre ' +
            'buscando promover melhorias em desempenho, usabilidade, acessibilidade, segurança e ' +
            'também outros aspectos como SEO e monitoramento. A aplicação web será construída com Next.js ' +
            'por ser um framework com foco em performance e SEO, utilizará a metodologia PWA e terá um ' +
            'design adaptável com duas versões, uma para dispositivos móveis e outra versão para desktop.',
        technologies: [
            "Next.js", "React", "Redux", "SASS", "Javascript", "Typescript",
            "HTML", "CSS", "Bootstrap", "Nginx", "Node.js", "Docker", "Datadog",
            "Sentry", "GTMetrix", "Pingdom", "Lighthouse", "PageSpeed Insights"
        ],
        url: 'https://new-ecommerce.test.wilsoncastro.dev/',
        status: 'Andamento',
        env: "test",
        demo: true,
        commits: <CommitsNECWA />,
        tasks: <TasksNECWA />,
        tasksCompleted: <TasksCompletedNECWA />,
        screenDesktop: [
            NECWAcreenanimation1, NECWAcreenshot1, NECWAcreenshot2, NECWAcreenshot3, NECWAcreenshot9,
            NECWAcreenshot10, NECWAcreenshot11, NECWAcreenshot12, NECWAcreenshot13, NECWAcreenshot14,
            NECWAcreenshot15, NECWAcreenshot16
        ],
        screenMobile: [NECWAcreenanimation2, NECWAcreenshot4, NECWAcreenshot5, NECWAcreenshot6, NECWAcreenshot7, NECWAcreenshot8],
        credits: {}
    },
    {
        id: 8,
        projectName: 'DevOps - wCastro',
        objective: `O objetivo do projeto DevOps é que eu, como "desenvolvedor", adquira noções de DevOps para
                aprender implementar e gerenciar microsserviços de maneira eficiente. Isso envolve o estudo de ferramentas 
                importantes como Terraform, Ansible, Docker/Docker Compose, Docker Swarm, Jenkins, Traefik e Vault. Atualmente, a minha infraestrutura 
                na AWS é bem limitada e serve apenas como um laboratório para aprendizado e experimentação.
                Isso me impede de estudar Kubernetes, uma tecnologia poderosa de orquestração 
                que exige uma infraestrutura mais robusta. No entanto, no futuro, espero ter a oportunidade 
                de estudar Kubernetes e aprofundar ainda mais minhas habilidades em DevOps ou até mesmo DevSecOps.`,
        technologies: [
            "Terraform", "Ansible", "Docker/Docker Compose", "Docker Swarm", "Jenkins", "Traefik", "Vault"
        ],
        url: '',
        github: '',
        status: 'Andamento',
        env: "test",
        demo: true,
        commits: null,
        tasks: null,
        tasksCompleted: null,
        screenDesktop: [DOArch1, DOArch2, DOScreenanimation1, DOScreenanimation2, DOScreenshot1, DOScreenshot2, DOScreenshot3],
        screenMobile: [],
        credits: {
            description: (
                <Fragment>
                    <p>
                        Seguem os créditos das Ferramentas de DevOps.
                    </p>
                </Fragment>
            ),
            numbersCredit: 1,
            icons: [],
            images: [],
            logos: [],
            tools: [
                {
                    "name": "Serviço 'Terraform' desenvolvido por HashiCorp",
                    "link": "https://www.terraform.io/",
                    "platform": "HashiCorp"
                },
                {
                    "name": "Serviço 'Ansible' desenvolvido por Michael DeHaan",
                    "link": "https://www.ansible.com/",
                    "platform": "Ansible"
                },
                {
                    "name": "Serviço 'Docker' desenvolvido por Docker Inc",
                    "link": "https://www.docker.com/",
                    "platform": "Docker"
                },
                {
                    "name": "Serviço 'Docker Compose' desenvolvido por Docker Inc",
                    "link": "https://docs.docker.com/compose/",
                    "platform": "Docker"
                },
                {
                    "name": "Serviço 'Docker Swarm' desenvolvido por Docker Inc",
                    "link": "https://docs.docker.com/engine/swarm/",
                    "platform": "Docker"
                },
                {
                    "name": "Serviço 'Jenkins' desenvolvido por Kohsuke Kawaguchi",
                    "link": "https://www.jenkins.io/",
                    "platform": "Jenkins"
                },
                {
                    "name": "Serviço 'Traefik' desenvolvido por Traefik Labs",
                    "link": "https://traefik.io/",
                    "platform": "Traefik"
                },
                {
                    "name": "Serviço 'Vault' desenvolvido por HashiCorp",
                    "link": "https://www.vaultproject.io/",
                    "platform": "HashiCorp"
                }
            ],            
            packages: [],
            apis: [],
            references: []
        }
    },
    {
        id: 9,
        projectName: 'Serviços - wCastro',
        objective: `O objetivo deste projeto é buscar e estudar constantemente serviços que simplifiquem o 
                    desenvolvimento, a manutenção, o monitoramento e a segurança das aplicações.`,
        technologies: [
            "Adminer",
            "Authelia",
            "Dozzle",
            "Duplicati",
            "Elastic-Agent",
            "Elasticsearch",
            "Filebrowser",
            "Fluentbit",
            "Gitea",
            "Glances",
            "Grafana",
            "Graylog",
            "Homer",
            "It-Tools",
            "Keycloak",
            "Kibana",
            "Mailhog",
            "Netdata",
            "Ntfy",
            "Portainer",
            "Rclone",
            "Registry",
            "Swarmpit",
            "Syslog",
            "Traefik",
            "Uptime-Kuma",
            "Vault",
            "Vaultwarden",
            "Wiseguard",
            "Yacht",
            "Yourls",
            "Zabbix"
        ],
        url: 'https://servicos.wilsoncastro.dev/',
        github: 'https://github.com/wilsoncastrodev/servicos-docker?tab=readme-ov-file#mapa-mental',
        status: 'Andamento',
        env: "test",
        demo: true,
        commits: null,
        tasks: null,
        tasksCompleted: null,
        screenDesktop: [
            SScreenshot1, SScreenshot2, SScreenshot3, SScreenshot8,
            SScreenshot5, SScreenshot6, SScreenshot7, SScreenshot9,
            SScreenshot10, SScreenshot11, SScreenshot12, SScreenshot13
        ],
        screenMobile: [],
        credits: {
            description: (
                <Fragment>
                    <p>
                        Seguem os créditos dos Serviços Docker.
                    </p>
                </Fragment>
            ),
            numbersCredit: 1,
            icons: [],
            images: [],
            logos: [],
            services: [
                {
                    "name": "Serviço 'Adminer' desenvolvido por Adminer",
                    "link": "https://hub.docker.com/_/adminer",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Dozzle' desenvolvido por Amir20",
                    "link": "https://hub.docker.com/r/amir20/dozzle",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Authelia' desenvolvido por Authelia",
                    "link": "https://hub.docker.com/r/authelia/authelia",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Homer' desenvolvido por B4bz",
                    "link": "https://hub.docker.com/r/b4bz/homer",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Syslog-ng' desenvolvido por Balabit",
                    "link": "https://hub.docker.com/r/balabit/syslog-ng",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Ntfy' desenvolvido por Binwiederhier",
                    "link": "https://hub.docker.com/r/binwiederhier/ntfy",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'IT Tools' desenvolvido por Corentinth",
                    "link": "https://hub.docker.com/r/corentinth/it-tools",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Elastic Agent' desenvolvido por Elastic",
                    "link": "https://hub.docker.com/r/elastic/elastic-agent",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Elasticsearch' desenvolvido por Elastic",
                    "link": "https://hub.docker.com/_/elasticsearch",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Kibana' desenvolvido por Elastic",
                    "link": "https://hub.docker.com/_/kibana",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Filebrowser' desenvolvido por Filebrowser",
                    "link": "https://hub.docker.com/r/filebrowser/filebrowser",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Fluent-bit' desenvolvido por Fluent",
                    "link": "https://hub.docker.com/r/fluent/fluent-bit",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Gitea' desenvolvido por Gitea",
                    "link": "https://hub.docker.com/r/gitea/gitea",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Grafana' desenvolvido por Grafana Labs",
                    "link": "https://hub.docker.com/r/grafana/grafana",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Loki' desenvolvido por Grafana Labs",
                    "link": "https://hub.docker.com/r/grafana/loki",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Promtail' desenvolvido por Grafana Labs",
                    "link": "https://hub.docker.com/r/grafana/promtail",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Graylog' desenvolvido por Graylog",
                    "link": "https://hub.docker.com/r/graylog/graylog",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Consul' desenvolvido por Hashi Corp, Inc.",
                    "link": "https://hub.docker.com/r/hashicorp/consul",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Vault' desenvolvido por Hashi Corp, Inc.",
                    "link": "https://hub.docker.com/r/hashicorp/vault",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'InfluxDB' desenvolvido por InfluxData",
                    "link": "https://hub.docker.com/r/influxdb/influxdb",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Jenkins' desenvolvido por Jenkins",
                    "link": "https://hub.docker.com/r/jenkins/jenkins",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Docker Registry UI' desenvolvido por Joxit",
                    "link": "https://hub.docker.com/r/joxit/docker-registry-ui",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Duplicati' desenvolvido por Linuxserver.io",
                    "link": "https://hub.docker.com/r/linuxserver/duplicati",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Wireguard' desenvolvido por Linuxserver.io",
                    "link": "https://hub.docker.com/r/linuxserver/wireguard",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Uptime Kuma' desenvolvido por Louislam",
                    "link": "https://hub.docker.com/r/louislam/uptime-kuma",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Mailhog' desenvolvido por Mailhog",
                    "link": "https://hub.docker.com/r/mailhog/mailhog",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Netdata' desenvolvido por Netdata",
                    "link": "https://hub.docker.com/r/netdata/netdata",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Glances' desenvolvido por Nicolargo",
                    "link": "https://hub.docker.com/r/nicolargo/glances",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Portainer' desenvolvido por Portainer.io",
                    "link": "https://hub.docker.com/r/portainer/portainer-ce",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Keycloak' desenvolvido por Keycloak",
                    "link": "https://hub.docker.com/r/keycloak/keycloak",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Rclone' desenvolvido por Rclone",
                    "link": "https://hub.docker.com/r/rclone/rclone",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Registry' desenvolvido por Docker",
                    "link": "https://hub.docker.com/_/registry",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Yacht' desenvolvido por Selfhostedpro",
                    "link": "https://hub.docker.com/r/selfhostedpro/yacht",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Swarmpit Agent' desenvolvido por Swarmpit",
                    "link": "https://hub.docker.com/r/swarmpit/agent",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Swarmpit' desenvolvido por Swarmpit",
                    "link": "https://hub.docker.com/r/swarmpit/swarmpit",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Traefik Forward Auth' desenvolvido por Thomseddon",
                    "link": "https://hub.docker.com/r/thomseddon/traefik-forward-auth",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Traefik' desenvolvido por Traefik Labs",
                    "link": "https://hub.docker.com/r/traefik/traefik",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Vaultwarden' desenvolvido por Vaultwarden",
                    "link": "https://hub.docker.com/r/vaultwarden/server",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Yourls' desenvolvido por Yourls",
                    "link": "https://hub.docker.com/r/yourls/yourls",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Zabbix Agent' desenvolvido por Zabbix",
                    "link": "https://hub.docker.com/r/zabbix/zabbix-agent2",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Zabbix Server (PostgreSQL)' desenvolvido por Zabbix",
                    "link": "https://hub.docker.com/r/zabbix/zabbix-server-pgsql",
                    "platform": "Docker Hub"
                },
                {
                    "name": "Serviço 'Zabbix Web (Nginx/PostgreSQL)' desenvolvido por Zabbix",
                    "link": "https://hub.docker.com/r/zabbix/zabbix-web-nginx-pgsql",
                    "platform": "Docker Hub"
                }
            ],            
            packages: [],
            apis: [],
            references: []
        }
    },
]