import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';

const ProfileDescription = () => {
    const initialState = {
        description: (
            <Fragment>
                <p className="mb-2">
                    Sou formado em Análise e Desenvolvimento de Sistemas pela Fatec São Caetano do Sul, 
                    trabalho no desenvolvimento de aplicações Web. Trabalhei como 
                    desenvolvedor Full Stack PHP Junior na empresa 
                    Studio Visual, atualmente tenho como objetivo trabalhar tanto no front-end 
                    quanto no back-end para atuar na criação de novas aplicações e na 
                    manutenção de projetos já existentes.
                </p>  
            </Fragment>
        ),
        features: [
            "Amo trabalhar com desenvolvimento Web;",
            "Tenho como objetivo estar sempre crescendo profissionalmente;",
            "Busco me manter atualizado com as novas tendências de desenvolvimento Web, como bibliotecas e frameworks;",
            "Procuro colocar meu foco na solução e não no problema;",
            "Busco sempre aprimorar o meu código para torná-lo mais claro e legível;",
            "Tenho uma boa capacidade na depuração de códigos existentes;",
            "Gosto de enfrentar desafios que estejam relacionados a programação e o desenvolvimento Web;",
        ]
    }
    
    const [profile] = useState(initialState);
    
    const renderProfileDescription = () => {
        return (
            <Fragment>
                <Head content={'Sobre Mim'} classes="h2 mt-1 mb-2" margin="mb-2" />
                <div className="pt-1">
                    { profile.description }
                </div>
                <ul className="row list-unstyled px-sm-2 mb-0 pt-1">
                    <div className="col-12 col-sm-6 ps-sm-2 mb-2 mb-sm-0">
                        {
                            profile.features.slice(0, 4).map((value, key) => (
                                <li key={key} className={key < 3 ? "mb-2" : ""}>
                                    <i className="fas fa-check me-1 text-green"></i> { value } 
                                </li>
                            ))
                        }
                    </div>
                    <div className="col-12 col-sm-6 mt-0 pe-sm-2">
                        {
                            profile.features.slice(4, 7).map((value, key) => (
                                <li key={key} className={key < 2 ? "mb-2" : ""}>
                                    <i className="fas fa-check me-1 text-green"></i> { value } 
                                </li>
                            ))
                        }
                    </div>
                </ul>
            </Fragment>
        )
    }

    return (
        <section className="profile-description h-100">
            <Card id={'profile-description'} content={ renderProfileDescription() } classes=""/>
        </section>
    )
}

export default ProfileDescription;
