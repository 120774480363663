import React, { Fragment, useState } from 'react';
import { Modal } from 'rsuite';
import { Scrollbars } from 'react-custom-scrollbars';

const ModalSimple = ({ content, title, btnColor, textColor, type, classes, openModal }) => {
    const initialState = {
        show: openModal || false
    }

    const [modal, setModal] = useState(initialState);

    const close = () => {
        setModal({ show: false });
    }

    const open = () => {
        setModal({ show: true });
    }

    let link = (
        <button onClick={open} className={"btn btn-primary btn-lg " + btnColor}>
            <i className="fas fa-external-link-alt icon-desktop"></i>
            <span>Ver Mais Informações</span>
        </button>
    );
    
    link = type === "link" ? <span className="link-modal text-uppercase" onClick={open}>{title}</span> : link;
    link = type === "link-text" ?
        <span className="link-modal text-uppercase text-decoration-underline p-0 fs-sm" onClick={open}>{title}</span> 
    : link;    

    return (
        <Fragment>
            {link}
            <Modal size={"lg"} backdrop={true} show={modal.show} onHide={close} className={classes}>
                <Modal.Header>
                    <Modal.Title><span className={textColor}>{title}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-none d-lg-block track-horizontal-hidden" style={{ width: "100%", height: "100%" }}>
                        <Scrollbars autoHide style={{ width: "100%", height: "100%" }}
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                        >
                            {content}
                        </Scrollbars>
                    </div>
                    <div className="d-block d-lg-none">
                        {content}
                    </div>
                    <div className="my-5 py-5 my-lg-0 py-lg-0 d-block d-lg-none"></div>
                </Modal.Body>
            </Modal>
        </Fragment>
     )
}

export default ModalSimple;