import React, { Fragment } from 'react';

const Planet = ( {satellite, firstNamePlanet, secondNamePlanet, lastNamePlanet} ) => {
    return (
        <Fragment>
            <div className="planet-development">
                <div id="orbit-system">
                    <div className="system">
                        <div className="satellite-orbit">
                            <div className="satellite">
                                <span>{satellite}</span>
                            </div>
                        </div>
                        <div className="planet">
                            <span>{firstNamePlanet}</span>
                            <span>{secondNamePlanet}</span>
                            <span>{lastNamePlanet}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
  
export default Planet;