import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';
import { Link } from 'react-router-dom';
import { FaFilePdf, FaUser } from "react-icons/fa";
import ButtonCopy from '../../../components/buttons/ButtonCopy';

const Main = () => {
    const initialState = {
        link: "https://wilsoncastro.dev",
        whatsapp: "https://api.whatsapp.com/send?text=https://wilsoncastro.dev",
        email: "mailto:?body=https://wilsoncastro.dev",
    }

    const [share] = useState(initialState);

    const renderMain = () => {
        return (
            <Fragment>
                <section className="main">
                    <div className="card-title text-center my-4">
                        <p className="card-text">Wilson Castro</p>
                        <span>Desenvolvedor Full Stack PHP Pleno</span>
                    </div>
                    <div className="mt-4 pb-2 text-center d-block d-md-none">
                        <a href={share.email} className="icon-box">
                            <i className="far fa-envelope icon-share"></i>
                        </a>
                        <a href={share.whatsapp} className="icon-box">
                            <i className="fab fa-whatsapp icon-share"></i>
                        </a>
                        <ButtonCopy link={share.link}/>
                    </div>
                    <div className="card-btn-group mt-4 text-center">
                        <Link className="btn btn-outline-light me-md-3 mb-3 mb-md-auto" to="/sobre">
                            <span className="me-2">Conheça o Meu Perfil</span>
                            <FaUser size={20} />
                        </Link>
                        <a href="https://wilsoncastro.dev/cv/cv-wilson-castro-da-paixao.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light">
                            <span className="me-2">Baixe o Meu Currículo</span>
                            <FaFilePdf size={21} />
                        </a>
                    </div>
                    <div className="mt-4 text-center d-none d-md-block">
                        <a href={share.email} className="icon-box">
                            <i className="far fa-envelope icon-share"></i>
                        </a>
                        <a href={share.whatsapp} className="icon-box">
                            <i className="fab fa-whatsapp icon-share"></i>
                        </a>
                        <ButtonCopy link={share.link}/>
                    </div>
                </section>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Card id={'main'} content={ renderMain() } particles={true} waves={true} classes={'bg-base'}/>
        </Fragment>
    )
}
  
export default Main;