import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';
import Badge from '../../../components/badges/Badge';

const KnowledgeComplementary = () => {
    const initialState = {
        developTools: [
            {
                name: "Visual Studio Code (VSCode)",
                type: "Ferramentas de Desenvolvimento"
            },
            {
                name: "phpMyAdmin",
                type: "Ferramentas de Desenvolvimento"
            },
            {
                name: "pgAdmin",
                type: "Ferramentas de Desenvolvimento"
            },
            {
                name: "DevTools",
                type: "Ferramentas de Desenvolvimento"
            },
        ],
        versionControlTools: [
            {
                name: "Bitbucket",
                type: "Ferramentas de Controle de Versão",
            },
            {
                name: "GitHub",
                type: "Ferramentas de Controle de Versão",
            },
        ],
        packageManagerTools: [
            {
                name: "Composer",
                type: "Ferramentas de Gerenciamento de Dependências/Pacotes",
            },
            {
                name: "NPM",
                type: "Ferramentas de Gerenciamento de Dependências/Pacotes",
            },
            {
                name: "Yarn",
                type: "Ferramentas de Gerenciamento de Dependências/Pacotes",
            },
        ],
        AutomatedTestTools: [
            {
                name: "PHPUnit",
                type: "Ferramentas de Testes Automatizados",
            },
        ],
        testAPIsTools: [
            {
                name: "Postman",
                type: "Ferramenta de Teste de APIs",
            },
            {
                name: "Insomnia",
                type: "Ferramenta de Teste de APIs",
            },
        ],
        testPerformanceTools: [
            {
                name: "GTMetrix",
                type: "Ferramenta de Teste de Performance",
            },
            {
                name: "Google Lighthouse",
                type: "Ferramenta de Teste de Performance",
            },
            {
                name: "Google PageSpeed",
                type: "Ferramenta de Teste de Performance",
            },
            {
                name: "Pingdom",
                type: "Ferramenta de Teste de Performance",
            },
        ],
        cacheTools: [
            {
                name: "Redis",
                type: "Ferramentas de Cache",
            },
        ],
        searchTools: [
            {
                name: "Elasticsearch",
                type: "Ferramentas de Busca",
            },
        ],
        monitoringTools: [
            {
                name: "Datadog",
                type: "Ferramenta de Monitoramento",
            },
            {
                name: "Sentry",
                type: "Ferramenta de Monitoramento",
            },
        ],
        designPatterns: [
            {
                name: "Padrão MVC",
                type: "Padrões de Projetos",
            },
            {
                name: "Padrão Observer",
                type: "Padrões de Projetos",
            },
            {
                name: "Padrão Repository",
                type: "Padrões de Projetos",
            },
            {
                name: "Padrão Service Layer",
                type: "Padrões de Projetos",
            }
        ],
        others: [
            {
                name: "PWA",
                type: "Outros Conhecimentos",
            },
            {
                name: "Ajax",
                type: "Outros Conhecimentos",
            },
            {
                name: "Axios",
                type: "Outros Conhecimentos",
            },
            {
                name: "JSON",
                type: "Outros Conhecimentos",
            },
            {
                name: "Design Responsivo",
                type: "Outros Conhecimentos",
            },
            {
                name: "Expressões Regulares (Regex)",
                type: "Outros Conhecimentos",
            },
        ]
    }

    const [knowledges] = useState(initialState);

    const renderKnowledgeComplementary = () => {
        return (
            <Fragment>
                <Head content={ 'Conhecimentos Complementares' } classes={'h2 mt-2'} />
                {
                    Object.values(knowledges).map((knowledge, index) => (
                        <Fragment key={index}>
                            <h6 className={index % 2 === 0 ? 'text-success mb-2' : 'text-base mb-2'}>{knowledge[0].type}</h6>
                            <div className="mb-2">
                                {
                                    knowledge.map((value, key) => (
                                        <Badge key={key} content={value.name} classes={index % 2 === 0 ? 'bg-success mb-2 me-2' : 'bg-primary mb-2 me-2'}/> 
                                    ))
                                }
                            </div>
                        </Fragment>
                    ))
                }
            </Fragment>
        )
    }

    return (
        <section className="knowledge-complementary">
            <Card id={'knowledge-complementary'} content={renderKnowledgeComplementary()} classes={'h-100 text-primary'} />
        </section>
    )
}

export default KnowledgeComplementary;