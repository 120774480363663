import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';

const Bible = () => {
    const initialState = {
        message: "O SENHOR Deus diz: “Os meus pensamentos não são como os seus pensamentos, " 
               + "e eu não ajo como vocês. Assim como o céu está muito acima da terra, assim " 
               + "os meus pensamentos e as minhas ações estão muito acima dos seus.",
        book: "Isaías 55:8-9 Bíblia NTLH"
    }

    const [bible] = useState(initialState);

    const renderBible = () => {
        return (
            <Fragment>
                <p className="mb-0 d-inline ">{bible.message}</p>
                <div className="mt-2 fs-md fw-bolder-light">
                    <div className="float-start mt-1">
                        {bible.book}
                    </div>
                    <div className="float-end">
                        <i className="fa fa-book-open icon-book-open"></i>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <section className="bible">
            <Card id={'bible'} content={renderBible()} classes={'card-head h-100 text-primary'} />
        </section>
    )
}

export default Bible;