import React from 'react';
import Card from '../../../components/cards/Card';

const CV = () => {
    const renderCV = () => {
        return (
            <div className="text-center">
                <i className="fas fa-file-invoice"></i>
                <a href="https://wilsoncastro.dev/cv/cv-wilson-castro-da-paixao.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light px-2 w-100" >
                    <span className="me-2">Baixar Currículo em PDF</span>
                </a>
            </div>
        )
    }

    return (
        <section className="cv">
            <Card id={'cv'} content={renderCV()} classes={'card-cv bg-base'} />
        </section>
    )
}

export default CV;