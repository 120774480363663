import React, { Fragment, useState } from 'react';
import HeadPage from '../../components/heads/HeadPage';
import BibleSection from './sections/Bible';
import PlanetDevelopmentSection from './sections/PlanetDevelopment';
import SkillsSection from './sections/Skills';
import EducationSection from './sections/Education';
import WorkExperienceSection from './sections/WorkExperience';
import SkillsComplementarySection from './sections/SkillsComplementary';
import KnowledgeComplementarySection from './sections/KnowledgeComplementary';
import Portfolio from './sections/Portfolio';
import { FaIdCard } from 'react-icons/fa';

const CV = () => {
    const initialState = {
        title: "Currículo",
    }

    const [cv] = useState(initialState);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12 mb-4">
                    <HeadPage icon={<FaIdCard className="ms-md-4 icon-cv" size={25} />} title={cv.title} classes="card-title-alt"/>
                </div>
                <div className="col-xl-3">
                    <div className="row mb-4">
                        <div className="col-xl-12">
                            <BibleSection />
                        </div>
                    </div>
                    <div className="row mb-4 d-none d-xl-block">
                        <div className="col-xl-12">
                            <PlanetDevelopmentSection />
                        </div>
                    </div>
                    <div className="row mb-4 d-none d-xl-block">
                        <div className="col-xl-12">
                            <SkillsSection />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-9 mb-4">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <EducationSection />
                        </div>
                        <div className="col-12 col-lg-6">
                            <WorkExperienceSection />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 col-lg-4 d-block d-md-none mb-3 mt-1">
                            <PlanetDevelopmentSection />
                        </div>
                        <div className="col-md-5 col-lg-4 d-block d-xl-none">
                            <SkillsSection />
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <div className="row">
                                <div className="col-12">
                                    <Portfolio />
                                </div>
                                <div className="col-12 d-block d-xl-none">
                                    <SkillsComplementarySection />
                                </div>
                                <div className="col-12 mb-md-4">
                                    <KnowledgeComplementarySection />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 d-none d-xl-block">
                            <SkillsComplementarySection />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
  
export default CV;