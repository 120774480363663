import React, { Fragment } from 'react';

const SummaryHome = ({ project, content }) => {
    let name,
        url,
        textColor,
        bgColor;

    if (project === "site-pessoal") {
        name = "Site Pessoal - wCastro";
        url = "/";
    }
    
    if (project === "ecommerce") {
        name = "E-commerce - wCastro";
        url = "";
        textColor = "text-green-dark";
        bgColor = "bg-green";
    }

    if (project === "whatsapp-chatbot") {
        name = "Whatsapp Chatbot - wCastro";
        url = "https://api.whatsapp.com/send?phone=5511914640755";
    }

    if (project === "dashboard-site-pessoal-api") {
        name = "Dashboard API - Site Pessoal";
        url = "";
        textColor = "text-green-dark";
        bgColor = "bg-green";
    }

    if (project === "new-ecommerce-client-web-app") {
        name = "New E-commerce Web App";
        url = "https://new-ecommerce.test.wilsoncastro.dev/";
    }

    if (project === "dashboard-site-pessoal") {
        name = "Dashboard - Site Pessoal";
        url = "https://dashboard.test.wilsoncastro.dev/";
    }
    
    return (
        <Fragment>
            <div className="d-flex">
                <div className={"flex-shrink-0 icon-letter icon-letter-md " + bgColor}>{name.charAt(0)}</div>
                <div>
                    <div className="fw-bolder-light">
                        {name} 
                        {
                        url ? <a className={"ms-2 " + (!textColor ? "text-green-400" : textColor)} 
                                 href={url} target="_blank" rel="noopener noreferrer">
                                    (Visualizar)
                              </a>: null
                        }
                    </div>
                    <div>{content}</div>
                </div>
            </div>
        </Fragment>
    )
}
  
export default SummaryHome;