import React, { Fragment, useState } from 'react';
import Card from '../../../components/cards/Card';

const Bible = () => {
    const initialState = {
        message: "A fé é a certeza de que vamos receber as coisas que esperamos "
               + "e a prova de que existem coisas que não podemos ver.",
        book: "Hebreus 11:1 Bíblia NTLH"
    }
    const [bible] = useState(initialState);

    const renderBible = () => {
        return (
            <Fragment>
                <i className="fa fa-book-open icon-book-open ms-2 ms-xxl-4"></i>
                <p className="mb-0 ms-xxl-3 d-inline text-green-400 fs-md fw-bolder-light-md">{ bible.message }</p>
                <span className="ms-2 text-green-400 fs-md fw-bolder-light">{ bible.book }</span>
            </Fragment>
        )
    }

    return (
        <section className="bible">
            <Card id={'bible'} content={renderBible()} classes={'card-head'} />
        </section>
    )
}
  
export default Bible;