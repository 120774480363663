import { Fragment } from 'react';
import BibleSection from './sections/BibleTop';
import MainSection from './sections/Main';
import CardImage from '../../components/cards/CardImage';
import Commits from '../../components/commits/Commits';
import Tasks from '../../components/tasks/Tasks';

const Home = () => {
    return (
        <Fragment>
            <div className="row mb-4">
                <div className="col-12">
                    <BibleSection />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-8 mb-4">
                    <MainSection />
                </div>
                <div className="col-xl-4 mb-4 d-none d-xl-block">
                    <CardImage classes={'bg bg-php h-100'} />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-5 mb-4">
                    <Tasks />
                </div>
                <div className="col-xl-7 mb-4">
                    <Commits />
                </div>
            </div>
            <div className="row mb-4 d-block d-xl-none">
                <div className="col-12">
                    <CardImage classes={'card-image-fixed bg bg-php h-100'} />
                </div>
            </div>
        </Fragment>
    )
}

export default Home;