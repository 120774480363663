import React, { Fragment, useState, useContext } from 'react';
import Card from '../cards/Card';
import Modal from '../modals/Modal';
import { policiesData } from '../../data/policiesData';
import Context from '../../contexts/Context';

const BarCookies = () => {
	const initialState = {
		show: true
	}

	const [barCookie, setbarCookie] = useState(initialState);
	const [policies] = useState(policiesData);
	const { setShowPopoverWhatsapp } = useContext(Context);

	const renderBarCookies = () => {
		return (
			<Fragment>
				<div className="row">
					<div className="col-sm-9 pb-3 pb-sm-0">
						Este site não faz uso de Cookies e nem de tecnologias semelhantes para coletar dados do usuário.
						Ao clicar em "Aceitar" ou ao continuar navegando no site wilsoncastro.dev,
						você concorda com a <Modal title={"Política de Privacidade"}
							content={policies}
							type={"link-text"}
							classes="rs-modal-md"
						/>.
					</div>
					<div className="col-sm-3 d-flex align-items-center">
						<button className="btn btn-outline-light w-100" onClick={() => { setbarCookie({ show: false }); setShowPopoverWhatsapp({ show: true }) }}>
							<span className="fs-sm">ACEITAR</span>
						</button>
					</div>
				</div>
			</Fragment>
		)
	}

	return (
		<Card content={renderBarCookies()} classes={'card-float ' + (barCookie.show ? '' : 'd-none')} />
	)
}

export default BarCookies;