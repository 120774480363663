import ParticlesJs from 'react-particles-js';
import React from '../../assets/images/logos-svgs/react-logo.svg';
import Laravel from '../../assets/images/logos-svgs/laravel-logo.svg';
import PHP from '../../assets/images/logos-svgs/php-logo.svg';
import Mysql from '../../assets/images/logos-svgs/mysql-logo.svg';
import Javascript from '../../assets/images/logos-svgs/javascript-logo.svg';

const Particles = () => {
  return (
    <ParticlesJs 
        style={{ position: "absolute" }}
        params={{
            particles: {
              number: {
                value: 20,
                density: {
                  enable: true,
                  value_area: 1362.9002517356944
                }
              },
              color: {
                value: '#d2d2d2'
              },
              shape: {
                type: 'images',
                image: [
                  {
                    src: React, 
                    width: 300,
                    height: 150
                  },
                  {
                    src: Laravel, 
                    width: 300,
                    height: 150
                  },
                  {
                    src: PHP, 
                    width: 170,
                    height: 90
                  },
                  {
                    src: Mysql, 
                    width: 170,
                    height: 100
                  },
                  {
                    src: Javascript, 
                    width: 150,
                    height: 150
                  },
                ]
              },
              opacity: {
                value: 0.2,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false
                }
              },
              size: {
                value: 40,
                random: {
                  enable: false,
                  minimumValue: 40
                },
              },
              line_linked: {
                enable: false,
                distance: 1000,
                color: "#ffffff",
                opacity: 0.5451601006942778,
                width: 1
              },
              move: {
                enable: true,
                speed: 0.3,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200
                }
              }
            },
            interactivity: {
              detect_on: 'window',
              events: {
                onhover: {
                  enable: false,
                  mode: 'repulse'
                },
                onclick: {
                  enable: false,
                  mode: 'push'
                },
                resize: true
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1
                  }
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 0.5684540486109416,
                  speed: 3
                },
                repulse: {
                  distance: 200,
                  duration: 0.4
                },
                push: {
                  particles_nb: 4
                },
                remove: {
                  particles_nb: 2
                }
              }
            },
            retina_detect: true
        }}
    />
  )
}
  
export default Particles;