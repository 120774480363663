import React from 'react';
import Card from '../../../components/cards/Card';
import { IoLogoGithub } from "react-icons/io";

const Github = () => {
    const renderCV = () => {
        return (
            <div className="text-center text-white">
                <span className="me-3">Acessar Github</span><IoLogoGithub className="" size={36} />
                <i className="ms-4 fas fa-angle-right"></i>
            </div>
        )
    }

    return (
        <section className="github">
            <a href="https://github.com/wilsoncastrodev" target="_blank" rel="noopener noreferrer" className="text-white" >
                <Card id={'github'} content={renderCV()} classes={'card-github'} />
            </a>
        </section>
    )
}

export default Github;