import React from 'react';
import Wave from 'react-wavify';
import IconProfile1 from '../../assets/images/icons/aprendizagem.png';
import IconProfile2 from '../../assets/images/icons/desenvolvedor.png';
import IconProfile3 from '../../assets/images/icons/web_design.png';
import IconProfile4 from '../../assets/images/icons/varios_dispositivos.png';
import IconProfile5 from '../../assets/images/icons/internet.png';

const Waves = () => {
    return (
        <div className="wave-footer">
            <Wave
                className='wave'
                fill='#fff'
                paused={false}
                options={{
                    height: 30,
                    amplitude: 45,
                    speed: 0.1,
                    points: 5
                }}
            />

            <span className="wave-icons">
                <img className="icon-profile icon-profile1" src={IconProfile1} alt="Ícone Perfil" />
                <img className="icon-profile icon-profile2" src={IconProfile2} alt="Ícone Perfil" />
                <img className="icon-profile icon-profile3" src={IconProfile3} alt="Ícone Perfil" />
                <img className="icon-profile icon-profile4" src={IconProfile4} alt="Ícone Perfil" />
                <img className="icon-profile icon-profile5" src={IconProfile5} alt="Ícone Perfil" />
            </span>
        </div>
    )
}
  
export default Waves;