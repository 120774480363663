import React, { Fragment, useEffect, useState } from 'react';
import Head from '../../components/heads/Head';
import Card from '../../components/cards/Card';

const Credits = ({ credits, classes, title }) => {
    const [services, setServices] = useState([]);
    const [tools, setTools] = useState([]);

    useEffect(() => {
        if (credits.services && credits.services.length > 0) {
            const columnSize = Math.ceil(credits.services.length / 3);
            setServices([
                credits.services.slice(0, columnSize),
                credits.services.slice(columnSize, columnSize * 2),
                credits.services.slice(columnSize * 2)
            ]);
        }
    }, [credits.services]);

    useEffect(() => {
        if (credits.tools && credits.tools.length > 0) {
            const columnSize = Math.ceil(credits.tools.length / 3);
            setTools([
                credits.tools.slice(0, columnSize),
                credits.tools.slice(columnSize, columnSize * 2),
                credits.tools.slice(columnSize * 2)
            ]);
        }
    }, [credits.tools]);

    const renderCredits = (credits) => {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        {credits.description}
                    </div>
                    {
                        credits.numbersCredit === 1 ? <Fragment>
                            {
                                services.length > 0 ? <Fragment>
                                    <Head content={'Serviços Docker'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                    {
                                        services.map((column) => (
                                            <div className="col-lg-4">
                                                {
                                                    column.map((credit, index) => (
                                                        <ul className="list-unstyled">
                                                            <div className="row">
                                                                <li key={index} className="mb-3">
                                                                    <div className="fw-bolder-light">
                                                                        {credit.name}
                                                                    </div>
                                                                    <div className="text-start">
                                                                        {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                            <i className="fas fa-link pe-1"></i>
                                                                            Acessar o serviço no {credit.platform}
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </Fragment> : null
                            }
                            {
                                tools.length > 0 ? <Fragment>
                                    <Head content={'Serviços Docker'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                    {
                                        tools.map((column) => (
                                            <div className="col-lg-4">
                                                {
                                                    column.map((credit, index) => (
                                                        <ul className="list-unstyled">
                                                            <div className="row">
                                                                <li key={index} className="mb-3">
                                                                    <div className="fw-bolder-light">
                                                                        {credit.name}
                                                                    </div>
                                                                    <div className="text-start">
                                                                        {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                            <i className="fas fa-link pe-1"></i>
                                                                            Acessar a ferramenta no {credit.platform}
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </Fragment> : null
                            }
                        </Fragment> :
                            <Fragment>
                                <div className="col-lg-4">
                                    <div className="row">
                                        {credits.images.length > 0 ?
                                            <div className="col-12">
                                                <Head content={'Imagens'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                                <ul className="list-unstyled">
                                                    {
                                                        credits.images.map((credit, index) => (
                                                            <li key={index} className="mb-3">
                                                                <div className="fw-bolder-light">
                                                                    {credit.name}
                                                                </div>
                                                                <div className="text-start">
                                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                        <i className="fas fa-link pe-1"></i>
                                                                        Acessar a imagem no {credit.platform}
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div> : null
                                        }
                                        {credits.icons.length > 0 ?
                                            <div className="col-12">
                                                <Head content={'Ícones'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                                <ul className="list-unstyled">
                                                    {
                                                        credits.icons.map((credit, index) => (
                                                            <li key={index} className="mb-3">
                                                                <div className="fw-bolder-light">
                                                                    {credit.name}
                                                                </div>
                                                                <div className="text-start">
                                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                        <i className="fas fa-link pe-1"></i>
                                                                        Acessar os ícones no {credit.platform}
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="row">
                                        {credits.packages.length > 0 ?
                                            <div className="col-12">
                                                <Head content={'Pacotes do Node'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                                <ul className="list-unstyled">
                                                    {
                                                        credits.packages.map((credit, index) => (
                                                            <li key={index} className="mb-3">
                                                                <div className="fw-bolder-light">
                                                                    {credit.name}
                                                                </div>
                                                                <div className="text-start">
                                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                        <i className="fas fa-link pe-1"></i>
                                                                        Acessar o pacote no {credit.platform}
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div> : null
                                        }
                                        {credits.apis.length > 0 ?
                                            <div className="col-12">
                                                <Head content={'APIs'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                                <ul className="list-unstyled">
                                                    {
                                                        credits.apis.map((credit, index) => (
                                                            credit.name ?
                                                                <li key={index} className="mb-3">
                                                                    <div className="fw-bolder-light">
                                                                        {credit.name}
                                                                    </div>
                                                                    <div className="text-start">
                                                                        {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                            <i className="fas fa-link pe-1"></i>
                                                                            Acessar a documentação da API no {credit.platform}
                                                                        </a>
                                                                    </div>
                                                                </li> : null
                                                        ))
                                                    }
                                                </ul>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="row">
                                        {credits.logos.length > 0 ?
                                            <div className="col-12">
                                                <Head content={'Logos'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                                <ul className="list-unstyled">
                                                    {
                                                        credits.logos.map((credit, index) => (
                                                            <li key={index} className="mb-3">
                                                                <div className="fw-bolder-light">
                                                                    {credit.name}
                                                                </div>
                                                                <div className="text-start">
                                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                        <i className="fas fa-link pe-1"></i>
                                                                        Acessar o logo no {credit.platform}
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div> : null
                                        }
                                        {credits.references.length > 0 ?
                                            <div className="col-12">
                                                <Head content={'Referências de Desenvolvimento'} classes={'h2 mt-2 d-inline-block ' + classes.textColor} />
                                                <ul className="list-unstyled">
                                                    {
                                                        credits.references.map((credit, index) => (
                                                            <li key={index} className="mb-3">
                                                                <div className="fw-bolder-light">
                                                                    {credit.name}
                                                                </div>
                                                                <div className="text-start">
                                                                    {credit.platform} | <a href={credit.link} target="_blank" rel="noopener noreferrer" className={classes.linkColor}>
                                                                        <i className="fas fa-link pe-1"></i>
                                                                        Acessar a documentação do {credit.platform}
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </Fragment>
                    }

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={classes}>
                {
                    !title ? (
                        <Head content={'Créditos'} classes={'mt-2 d-inline-block ' + classes.textColor} />
                    ) : null
                }
                <Card content={renderCredits(credits, classes)} />
            </div>
        </Fragment>
    )
}

export default Credits;