import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import App from './App';
/* import { AddToHomeScreen } from 'react-pwa-add-to-homescreen'; */

ReactDOM.render(
  <React.StrictMode>
    {/* <AddToHomeScreen 
      translate={{ 
        headline: 'Adicione o wilsoncastro.dev a sua tela inicial', 
        chromiumInstall: 'Acesse o wilsoncastro.dev de forma rápida pelo aplicativo que será instalado na tela inicial do seu dispositivo' ,
        buttonInstall: 'Instalar' ,
        chromiumAddHomeScreen: 'Acesse o wilsoncastro.dev de forma rápida pelo aplicativo que será instalado na tela inicial do seu dispositivo' ,
        safariAddHomeScreen: 'Acesse o wilsoncastro.dev de forma rápida pelo aplicativo que será instalado na tela inicial do seu dispositivo' ,
      }},
      cookie={{
          name: 'add-to-home-screen-pwa',
          expireDays: 1
      }}
    /> */}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
