import React, { Fragment } from 'react';
import Planet from '../../../components/planet/Planet';
import Card from '../../../components/cards/Card';

const PlanetDevelopment = () => {
    const renderPlanetDevelopment = () => {
        return (
            <Fragment>
                <Planet satellite={'Wilson'} firstNamePlanet={'Área'} secondNamePlanet={'Desenvolvimento'} lastNamePlanet={'Web'} />
            </Fragment>
        )
    }

    return (
        <section className="planet-development">
            <Card id={'bible'} content={renderPlanetDevelopment()} classes={'card-cv bg-base'} />
        </section>
    )
}

export default PlanetDevelopment;