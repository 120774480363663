import React, { Fragment } from 'react';

const Badge = ({ id, content, classes }) => {
  return (
    <Fragment key={id}>
        <span className={'badge ' + (classes || 'bg-primary')}>{content}</span>
    </Fragment>
  )
}
  
export default Badge;