import React from 'react';

const ButtonFloatingToggle = ( {icon, classes} ) => {
  const handleClick = (e) => {
    let element = document.documentElement,
        icon =  e.currentTarget.querySelector('i');

    if (document.fullscreenElement) {
      icon.classList.remove('fa-compress');
      icon.classList.add('fa-expand');
      document.exitFullscreen()
    } else {
      icon.classList.remove('fa-expand');
      icon.classList.add('fa-compress'); 
      element.requestFullscreen() 
    }
  }

  return <button className={"btn " + classes} onClick={handleClick}>
      {icon}
  </button>
}

export default ButtonFloatingToggle;