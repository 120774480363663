import React, { Fragment, useEffect } from 'react';
import Card from '../../../components/cards/Card';
import Head from '../../../components/heads/Head';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { FaPaperPlane } from 'react-icons/fa';

const FormContact = () => {
    useEffect(() => {
        let forms = document.querySelectorAll(".needs-validation");

        Array.prototype.slice.call(forms).forEach((form) => {
            form.addEventListener("submit", (e) => {
                if (!form.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    sendEmail(e);

                    let confirm = document.querySelector('.swal2-confirm');

                    confirm.addEventListener("click", () => {
                        form.reset();
                        form.classList.remove("was-validated");
                    });
                }

                form.classList.add("was-validated");
            });
        });
    });

    const sendEmail = (e) => {
        e.preventDefault();

        const MySwal = withReactContent(Swal)

        MySwal.fire({
                didOpen: () => {
                    MySwal.clickConfirm()
                }   
            }).then(() => {
                return MySwal.fire('Mensagem Enviada', 'Sua mensagem foi enviada com sucesso!', 'success');
            }
        )

        emailjs.sendForm(
            process.env.REACT_APP_EMAIL_JS_SERVICE_ID, 
            process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, 
            e.target, 
            process.env.REACT_APP_EMAIL_JS_USER_ID
        );
    }

    const renderFormContact = () => {
        return (
            <Fragment>
                <Head content={'Entre em Contato'} classes="h2 mt-0" />
                <div className="mb-3 fw-">
                    Por favor, sinta-se à vontade para me enviar uma mensagem. Estarei entrando em contato o mais breve possível.
                </div>
                <form className="needs-validation" noValidate>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Nome</label>
                        <input type="text" name="name" className="form-control" id="name" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" name="email" className="form-control" id="email" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Celular (WhatsApp)</label>
                        <input type="text" name="phone" className="form-control" id="phone" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Mensagem</label>
                        <textarea name="message" className="form-control"  id="message" rows="5" required></textarea>
                    </div>
                    <div className="text-end mt-4">
                        <button type="submit" className="btn btn-success btn-lg">
                            <FaPaperPlane className="me-2" size={22} /> Enviar Mensagem
                        </button>
                    </div>
                </form>
            </Fragment>
        )
    }

    return (
        <section className="form-contact">
            <Card id={'form-contact'} content={renderFormContact()} />
        </section>
    )
}

export default FormContact;