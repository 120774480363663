import React, {Fragment} from 'react';
import { useTable } from "react-table";
import { Scrollbars } from 'react-custom-scrollbars';

const Table = ({ data, columns, classes, scroll }) => {
    const tableInstance = useTable({ columns, data })

    const renderTable = (tableInstance) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = tableInstance

        return (
            <Fragment>
                <table {...getTableProps()} className={"table " + classes} style={columns.length > 1 ? {minWidth: "650px"} : null}>
                <thead>
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                            <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                            </td>
                            )
                        })}
                        </tr>
                    )
                    })}
                </tbody>
                </table>
            </Fragment>
        )
    }

    return (
        <div className="table-responsive" >
            {
                scroll ? (
                    <Fragment>
                         <Scrollbars className="d-none d-lg-block track-horizontal-hidden" hideTracksWhenNotNeeded style={{ width: "100%", height: "100%" }}
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                            >
                            {renderTable(tableInstance)}
                        </Scrollbars>
                        <div className="d-block d-lg-none">
                            {renderTable(tableInstance)}
                        </div>
                    </Fragment>
                ) : renderTable(tableInstance)
            }
        </div>
    )
}

export default Table;