import React, { Fragment } from 'react';
import CardImageFull from '../../components/cards/CardImageFull';

const Screenshots = ({ screenDesktop, screenMobile }) => {
    return (
        <Fragment>
            <div className='row'>
            {
                screenDesktop && screenDesktop.length > 0 ?
                screenDesktop.map((screenshot, index) => (
                    <div key={index} className={"mb-4 col-12"}>
                        <CardImageFull image={screenshot} />
                    </div>
                )) : null
            }
            {
                screenMobile && screenMobile.length > 0 ?
                screenMobile.map((screenshot, index) => (
                    <div key={index} className={"mb-4 col-12 col-md-4"}>
                        <CardImageFull image={screenshot} />
                    </div>
                )) : null
            }
            </div>
        </Fragment>
    )
}
  
export default Screenshots;