import React, { Fragment } from 'react';
import Head from '../../components/heads/Head';
import Card from '../../components/cards/Card';
import Badge from '../../components/badges/Badge';
import Modal from '../../components/modals/Modal';
import ProjectDetails from './ProjectDetails';

const Project = ({ project, id }) => {
    let bgColor = id % 2 !== 0 ? 'bg-green' : '',
        textColor = id % 2 !== 0 ? 'text-success' : '',
        btnColor = id % 2 !== 0 ? 'btn-success' : '',
        badgeColor = id % 2 !== 0 ? 'bg-success mb-2 me-2' : 'bg-primary mb-2 me-2';

    const renderProject = (project, id) => {
        return (
            <Fragment>
                <Head content={ "Projeto: " + project.projectName } classes={'h2 mt-2 ' + textColor} />
                <div className="d-block d-sm-flex text-center text-sm-start">
                    <div className={"flex-shrink-0 mx-auto me-sm-3 icon-letter icon-letter-lg " + bgColor}>
                        {project.projectName.charAt(0)}
                    </div>
                    <div className="mt-3 mt-sm-auto">
                        <div dangerouslySetInnerHTML={{ __html: project.objective }} />
                        <div className="mt-3">
                            <div className="me-2 mb-2 fw-bolder-light">
                                {/* {project.status === "Não Iniciado" ? "Tecnologias que serão aplicadas no projeto:" : "Tecnologias aplicadas no projeto:"} */}
                                {id === 8 ? "Serviços que serão estudados:" : "Tecnologias aplicadas no projeto:"}
                            </div>
                            {
                                project.technologies.map((technology, id) => (
                                    <Badge key={id} content={technology} classes={badgeColor}/> 
                                ))
                            }
                        </div>
                        <div className="text-center text-sm-end mt-3 mt-sm-4">
                            <Modal btnColor={btnColor} textColor={textColor} title={"Projeto: " + project.projectName} 
                                   content={<ProjectDetails project={project} id={id} />}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <section className="project">
            <Card id={'project' + project.id} content={ renderProject(project, id) } />
        </section>
    )
}

export default Project;